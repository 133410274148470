import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Separator } from 'app/components/Common/Separator'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { chunk } from 'lodash'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  cta?: ButtonProps[]
  items: ItemProps[]
  title?: string
}

export const Mosaic = memo(function Mosaic({ cta, items, title }: Props) {
  if (items.length < 1) {
    return null
  }

  const chunkedItems = chunk(items, 3)

  return (
    <Container>
      <Separator />
      {title ? <Title>{title}</Title> : null}
      <Fade bottom distance="3.75rem">
        <>
          <List>
            {chunkedItems.map((items, index) => (
              <ItemsWrap key={index} row wrap>
                {uniq(items).map((item, index) => (
                  <Item key={index} {...item} />
                ))}
              </ItemsWrap>
            ))}
          </List>
          {cta
            ? cta.map((item, index) => (
                <CTA key={index} {...item} variant="simple" />
              ))
            : null}
        </>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  max-width: 82.5rem;
  margin: 6rem auto;
  padding: 0 1.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 3.75rem auto;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin-bottom: 3.4375rem;

  @media (max-width: 1023px) {
    margin-bottom: 2.25rem;
  }
`

const List = styled.div``

const ItemsWrap = styled(FlexBox)`
  position: relative;
  &:nth-of-type(2n) {
    > div {
      &:first-of-type {
        order: 3;
      }
      &:nth-of-type(3) {
        right: auto;
        left: 0;
      }
    }
  }
`

const CTA = styled(Button)`
  margin-top: 3.4375rem;

  @media (max-width: 1023px) {
    margin-top: 3.4375rem;
  }
`
